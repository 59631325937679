import React from 'react';

import Dashboard from '../components/Dashboard';
import { useSearchParams } from 'react-router-dom';
import { Text } from '../cdl/Text';
import { Box } from '../cdl/Box';

const CustomerHealthDashboard = () => {
    const [searchParams, _] = useSearchParams();
    const customerGroupId = searchParams.get('customerGroupId');
    const customerGroupName = searchParams.get('customerGroupName');
    const filter = customerGroupId
        ? {
              $or: [
                  { 'customer.customerGroupId': customerGroupId },
                  { groupId: customerGroupId }
              ]
          }
        : {};

    return (
        <>
            {customerGroupName ? (
                <Box marginLeft={6} marginTop={3}>
                    <Text variant="headline" color={'dark-blue.0'}>
                        {customerGroupName}
                    </Text>
                </Box>
            ) : null}
            <Dashboard
                dashboardId={'18840343-895f-4b51-a85a-8959ecfab99f'}
                filter={filter}
            />
        </>
    );
};

export default CustomerHealthDashboard;
