export const createColorVariants = ({ suffix, themeKey }) => {
    return {
        [`solid-${suffix}`]: {
            color: 'white',
            backgroundColor: `${themeKey}.0`,
            ':hover': {
                backgroundColor: `${themeKey}.1`
            },
            ':active': {
                backgroundColor: `${themeKey}.1`,
                borderColor: `${themeKey}.0`
            },
            ':disabled': {
                backgroundColor: `dark-blue.4`,
                color: `dark-blue.2`,
                cursor: 'not-allowed'
            }
        },
        [`outline-${suffix}`]: {
            color: `${themeKey}.0`,
            borderColor: `${themeKey}.3`,
            backgroundColor: 'transparent',
            ':hover': {
                borderColor: `${themeKey}.8`,
                backgroundColor: `${themeKey}.8`
            },
            ':active': {
                backgroundColor: `${themeKey}.8`,
                borderColor: `${themeKey}.3`
            },
            ':disabled': {
                backgroundColor: 'transparent',
                borderColor: 'dark-blue.4',
                color: 'dark-blue.2',
                cursor: 'not-allowed'
            }
        },
        [`light-${suffix}`]: {
            color: `${themeKey}.0`,
            backgroundColor: 'transparent',
            ':hover': {
                borderColor: `${themeKey}.8`,
                backgroundColor: `${themeKey}.8`
            },
            ':active': {
                backgroundColor: `${themeKey}.8`,
                borderColor: `${themeKey}.3`
            },
            ':disabled': {
                color: 'dark-blue.2',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                cursor: 'not-allowed'
            }
        }
    };
};
