export const colors = {
    'dark-blue': [
        '#193643',
        '#475e68',
        '#75878e',
        '#a3afb4',
        '#d1d7d9',
        '#dcdfe1',
        '#edeff0',
        '#f1f3f4',
        '#f6f7f7',
        '#fafbfb',
        '#fdfdfd'
    ],
    'mellow-teal': [
        '#1de9b6',
        '#4aedc5',
        '#77f2d3',
        '#a5f6e2',
        '#d2fbf0',
        '#e4f8f3',
        '#e8f9f5',
        '#edf9f6',
        '#f1f9f7',
        '#f6faf9',
        '#f8faf9'
    ],
    'vibrant-aqua': [
        '#0001f2',
        '#3301f5',
        '#6601f7',
        '#9901fa',
        '#cc01fc',
        '#e1fbf9',
        '#e6faf9',
        '#ebfafa',
        '#f0fafa',
        '#f5fafa',
        '#f8fafa'
    ],
    'striking-purple': [
        '#6700fe',
        '#8633fe',
        '#a466fe',
        '#c299ff',
        '#e1ccff',
        '#ebe1fa',
        '#eee6fa',
        '#f1ebfa',
        '#f4f0fa',
        '#f7f5fa',
        '#f9f8fa'
    ],
    'clear-blue': [
        '#0068FF',
        '#3386FF',
        '#66a4FF',
        '#99c3FF',
        '#cce1FF',
        '#e1ebfb',
        '#e6eefa',
        '#ebf1fa',
        '#f0f4fa',
        '#f5f7fa',
        '#f8f9fa'
    ],
    'shining-yellow': [
        '#ffe200',
        '#ffe833',
        '#ffee66',
        '#fff499',
        '#fff9cc',
        '#fbf8e1',
        '#faf8e6',
        '#faf9eb',
        '#faf9f0',
        '#fafaf5',
        '#fafaf8'
    ],
    'signal-red': [
        '#fe355a',
        '#fe5d7b',
        '#fe869c',
        '#FFAEBD',
        '#ffd7de',
        '#fae6ea',
        '#faeaed',
        '#faeef0',
        '#faf2f4',
        '#faf6f7',
        '#faf8f8'
    ],
    'confirming-green': [
        '#00cc69',
        '#32d586',
        '#64dfa3',
        '#96e8c0',
        '#c8f1dd',
        '#e1f5eb',
        '#e6f6ee',
        '#ebf7f1',
        '#f0f8f4',
        '#f5f9f7',
        '#f8faf9'
    ],
    'bold-blueish': [
        '#0001FE',
        '#3334FE',
        '#6667FE',
        '#9999FF',
        '#CCCCFF',
        '#CCCCFF',
        '#E6E6FA',
        '#EBEBFA',
        '#F0F0FA',
        '#F5F5FA',
        '#F8F8FA'
    ],
    white: ['#fff'],
    black: ['#000'],

    // New colors from CDL
    foreground: {
        default: '#193643',
        muted: '#466573',
        subtle: '#5F7D8B',
        disabled: '#B0C8D2',
        onEmphasis: '#FFFFFF'
    },
    background: {
        default: '#FFFFFF',
        subtle: '#F0F7FA80',
        inset: '#E2EFF580',
        disabled: '#E2EFF5',
        muted: '#CEE2EA',
        emphasis: '#193643',
        dimming: '#19364333'
    },
    border: {
        default: '#B0C8D2',
        muted: '#CEE2EA',
        emphasis: '#193643'
    },
    accent: {
        foreground: '#0056D5',
        emphasis: '#0068FF',
        border: '#B5DDFF',
        background: '#EEF9FF'
    },
    positive: {
        foreground: '#00924B',
        emphasis: '#00CC69',
        border: '#82F5BD',
        background: '#DAFEEC'
    },
    attention: {
        foreground: '#B67600',
        emphasis: '#FFA500',
        border: '#FFE692',
        background: '#FFFBE3'
    },
    negative: {
        foreground: '#D41A3C',
        emphasis: '#FE355A',
        border: '#FFD2DF',
        background: '#FFF7FB'
    }
};
