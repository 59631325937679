import styled from 'styled-components';
import {
    border,
    BorderProps,
    boxShadow,
    BoxShadowProps,
    color,
    ColorProps,
    compose,
    flexbox,
    FlexboxProps,
    grid,
    GridProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    space,
    SpaceProps,
    variant
} from 'styled-system';

import { theme } from './theme.js';

export const Box = styled.div(
    {
        boxSizing: 'border-box'
    },
    variant({
        variants: {
            card: {
                boxShadow: 1,
                borderRadius: 1,
                backgroundColor: 'white',
                padding: 6
            },
            shadow: {
                boxShadow: 1,
                borderRadius: 1
            },
            terminal: {
                backgroundColor: 'black',
                color: 'white',
                padding: 4
            }
        }
    }),
    compose(space, color, layout, flexbox, border, position, boxShadow, grid)
);

Box.defaultProps = {
    theme
};
