import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../components/TopBar';
import { Box } from '../cdl/Box';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex } from '../cdl/Flex';
import { LoginBackgroundImage } from '../components/LoginBackgroundImage';
import { Text } from '../cdl/Text';
import { Button } from '../cdl/Button';
import styled from 'styled-components';

import dataMan from '../images/illustrations/data.png';

const DataMan = styled.div`
    background-image: url('${dataMan}');
    background-size: 300px 300px;
    width: 300px;
    height: 300px;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const BaseView = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    return (
        <Flex flexDirection={'column'} height={'100vh'}>
            <TopBar />
            <Box flexGrow={1}>
                {isAuthenticated ? (
                    <Outlet />
                ) : (
                    <>
                        <Flex
                            marginTop={10}
                            alignItems="center"
                            flexDirection="column"
                        >
                            <Text variant="headline" color={'dark-blue.0'}>
                                Closelink Charts
                            </Text>
                            <DataMan />
                            <Button
                                color="solid-clear"
                                onClick={() =>
                                    loginWithRedirect({
                                        appState: {
                                            returnTo: window.location.href
                                        },
                                        onRedirectCallback: (appState) => {
                                            window.history.replace(
                                                appState && appState.returnTo
                                                    ? appState.returnTo
                                                    : window.location.href
                                            );
                                        }
                                    })
                                }
                                size="large"
                            >
                                Log in
                            </Button>
                        </Flex>
                        <LoginBackgroundImage />
                    </>
                )}
            </Box>
        </Flex>
    );
};

export default BaseView;
