import { useAuth0 } from '@auth0/auth0-react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import React, { useEffect, useRef, useState } from 'react';

const Dashboard = ({ dashboardId, filter }) => {
    const { getIdTokenClaims } = useAuth0();

    const sdk = new ChartsEmbedSDK({
        baseUrl: 'https://charts.mongodb.com/charts-closelink-production-rxjha', // Optional: ~REPLACE~ with the Base URL from your Embed Dashboard dialog
        getUserToken: async function () {
            const token = await getIdTokenClaims();
            return token.__raw;
        }
    });
    const dashboardDiv = useRef(null);
    const [, setRendered] = useState(false);
    const [dashboard] = useState(
        sdk.createDashboard({
            dashboardId: dashboardId,
            theme: 'light',
            background: 'transparent',
            filter: filter
        })
    );

    useEffect(() => {
        dashboard
            .render(dashboardDiv.current)
            .then(() => setRendered(true))
            .catch((err) => console.log('Error during Charts rendering.', err));
    }, [dashboard]);

    return (
        <div
            style={{ height: '100%', width: '100%' }}
            className="dashboard"
            ref={dashboardDiv}
        />
    );
};

export default Dashboard;
