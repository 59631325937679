export const SvgUp = (props) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.507 13.707a1 1 0 010-1.414l2.828-2.829a1 1 0 011.415 0l2.828 2.829a1 1 0 01-1.414 1.414l-2.122-2.121-2.12 2.121a1 1 0 01-1.415 0z"
            fill="currentColor"
        />
    </svg>
);
SvgUp.defaultProps = {
    display: 'block'
};
