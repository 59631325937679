import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { theme } from './theme';

export const ThemeProvider = ({ children }) => {
    return (
        <StyledComponentsThemeProvider theme={theme}>
            {children}
        </StyledComponentsThemeProvider>
    );
};
