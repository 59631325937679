import styled from 'styled-components';
import css from '@styled-system/css';
import { Flex } from '../cdl/Flex';
import { SvgDiamond } from '../images/icons/Diamond';
import { theme } from '../cdl/theme';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useRef, useState } from 'react';
import { SvgUp } from '../images/icons/Up';
import { SvgDown } from '../images/icons/Down';
import { Box } from '../cdl/Box';
import { useOutsideClick } from '../utils/useOutsideClick';
import { Button } from '../cdl/Button';
import { Text } from '../cdl/Text';

const TopbarItem = styled(Flex)(
    css({
        paddingX: 7,
        borderRight: 1,
        alignItems: 'center',
        ':last-of-type': {
            borderRight: 'none'
        }
    })
);

const TabBarTab = styled(NavLink)(
    css({
        border: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: '18px',
        color: 'dark-blue.2',
        ':hover': {
            color: 'dark-blue.0'
        },
        textDecoration: 'none',
        '&.active': {
            color: 'dark-blue.0',
            borderBottom: 2
        }
    })
);

TabBarTab.defaultProps = {
    theme
};

const TabBar = styled.div(
    css({
        marginTop: '5px',
        [TabBarTab]: {
            marginRight: 4,
            ':last-of-type': {
                marginRight: 0
            }
        }
    })
);

const UserAvatar = styled(Flex)(
    css({
        backgroundColor: 'striking-purple.0',
        borderRadius: 2,
        height: '32px',
        width: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        ':hover': {
            opacity: 0.75
        }
    })
);

const Arrow = styled(Box)(
    css({
        borderRadius: 2,
        backgroundColor: 'white',
        width: '16px',
        height: '16px',
        color: 'black',
        position: 'absolute',
        right: 0,
        top: '10px',
        transform: 'translateX(50%)',
        cursor: 'pointer'
    })
);

const UserMenuLink = styled(Box).attrs({
    as: 'a'
})(
    css({
        display: 'block',
        paddingX: 4,
        paddingY: 3,
        borderBottom: 1,
        minWidth: '120px',
        cursor: 'pointer',
        ':hover': {
            color: 'clear-blue.0',
            textDecoration: 'none',
            backgroundColor: 'clear-blue.9'
        }
    })
);

const UserMenuPopout = styled(Box)(
    css({
        position: 'absolute',
        backgroundColor: 'white',
        top: '40px',
        right: '-10px',
        border: 1,
        borderRadius: 1,
        boxShadow: 1
    })
);

const TopBar = () => {
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const userMenuRef = useRef(null);
    const avatarRef = useRef(null);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin
        });

    const [showMenu, setShowMenu] = useState(false);

    useOutsideClick([userMenuRef, avatarRef], () => setShowMenu(false));

    return (
        <Flex
            padding={5}
            backgroundColor="white"
            height="64px"
            alignItems="center"
            borderBottom={1}
            variant="shadow"
            // this was the topbar's previous z-index, using the same index for
            // compatibility:
            zIndex="2025"
        >
            <Flex width="100%" alignItems="center">
                <TopbarItem paddingY={4}>
                    <SvgDiamond />
                </TopbarItem>
                <TopbarItem flexGrow={1}>
                    {isAuthenticated ? (
                        <TabBar>
                            <TabBarTab to="/">Overview</TabBarTab>
                            <TabBarTab to="/lubes-kpis">Lubes KPIs</TabBarTab>
                            <TabBarTab to="/lubes-dashboard">
                                Lubes Dashboard
                            </TabBarTab>
                            <TabBarTab to="/fuel-dashboard">
                                Fuel Dashboard
                            </TabBarTab>
                            <TabBarTab to="/customer-health">
                                Customer Health
                            </TabBarTab>
                        </TabBar>
                    ) : null}
                </TopbarItem>
                <TopbarItem>
                    {!isAuthenticated ? (
                        <Button
                            color="solid-clear"
                            onClick={() =>
                                loginWithRedirect({
                                    appState: {
                                        returnTo: window.location.href
                                    },
                                    onRedirectCallback: (appState) => {
                                        window.history.replace(
                                            appState && appState.returnTo
                                                ? appState.returnTo
                                                : window.location.href
                                        );
                                    }
                                })
                            }
                            size="medium"
                        >
                            Log in
                        </Button>
                    ) : (
                        <Box position="relative">
                            <UserAvatar
                                ref={avatarRef}
                                onClick={() => setShowMenu((prev) => !prev)}
                            >
                                {user.picture ? (
                                    <img
                                        src={user.picture}
                                        alt={'Profile'}
                                        width="32"
                                    />
                                ) : (
                                    <Text color="white" weight="semi" size={10}>
                                        {user.given_name[0]}
                                        {user.family_name[0]}
                                    </Text>
                                )}
                            </UserAvatar>
                            <Arrow onClick={() => setShowMenu((prev) => !prev)}>
                                {showMenu ? (
                                    <SvgUp width={16} height={16} />
                                ) : (
                                    <SvgDown width={16} height={16} />
                                )}
                            </Arrow>
                            {showMenu ? (
                                <UserMenuPopout ref={userMenuRef}>
                                    <UserMenuLink onClick={logoutWithRedirect}>
                                        Logout
                                    </UserMenuLink>
                                </UserMenuPopout>
                            ) : null}
                        </Box>
                    )}
                </TopbarItem>
            </Flex>
        </Flex>
    );
};

export default TopBar;
