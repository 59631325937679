import css from '@styled-system/css';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { createColorVariants } from './createColorVariants';

const ButtonContentWrapper = styled.span(
    css({
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    })
);

const StyledTextWrapper = styled.span({});

const StyledElementWrapper = styled.span({
    height: '1em',
    overflow: 'visible',
    transform: 'translateY(-0.35em)',
    display: 'inline-block',
    verticalAlign: 'middle',
    ':first-child': {
        marginLeft: '-4px',
        marginRight: '4px'
    },
    ':last-child': {
        marginRight: '-4px',
        marginLeft: '4px'
    },
    ':only-child': {
        marginLeft: '-8px',
        marginRight: '-8px',
        height: '1.5em',
        transform: 'translateY(-0.2em)'
    }
});

const StyledButton = styled('button')(
    css({
        border: '1px solid transparent',
        borderRadius: 1,
        display: 'inline-block',
        outline: 'none',
        cursor: 'pointer',
        verticalAlign: 'middle',
        position: 'relative',
        boxSizing: 'border-box',
        fontWeight: 500
    }),
    (props) => {
        if (props.sticky) {
            return {
                position: 'sticky',
                bottom: 0 // we don't need the entire position system, but it's helping in this case
            };
        }
    },
    (props) => {
        if (props.fullWidth) {
            return {
                width: '100%'
            };
        }
    },
    variant({
        prop: 'size',
        variants: {
            small: {
                fontSize: '10px',
                lineHeight: '14px',
                padding: '8px 12px',
                height: '32px',
                svg: {
                    height: '16px'
                },
                'svg:first-child': {
                    marginLeft: '-4px',
                    marginRight: '4px'
                },
                'svg:last-child': {
                    marginRight: '-4px',
                    marginLeft: '4px'
                },
                'svg:only-child': {
                    marginLeft: '-4px',
                    marginRight: '-4px'
                }
            },
            medium: {
                padding: '8px 16px'
            },
            large: {
                padding: '22px 64px'
            },
            submit: {
                padding: '22px 64px',
                borderRadius: 0,
                display: 'block',
                width: '100%'
            }
        }
    }),
    variant({
        prop: 'color',
        variants: {
            ...createColorVariants({
                suffix: 'clear',
                themeKey: 'clear-blue'
            }),
            ...createColorVariants({ suffix: 'dark', themeKey: 'dark-blue' }),
            ...createColorVariants({ suffix: 'red', themeKey: 'signal-red' }),
            ...createColorVariants({
                suffix: 'green',
                themeKey: 'confirming-green'
            })
        }
    })
);

// eslint-disable-next-line react/display-name
export const Button = forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { children, size = 'medium', ...rest } = props;

    const content = (
        <React.Fragment>
            {React.Children.map(children, (child) => {
                return typeof child === 'string' ? (
                    <StyledTextWrapper>{child}</StyledTextWrapper>
                ) : (
                    <StyledElementWrapper>{child}</StyledElementWrapper>
                );
            })}
        </React.Fragment>
    );

    return (
        <StyledButton {...rest} size={size} ref={ref}>
            <ButtonContentWrapper>{content}</ButtonContentWrapper>
        </StyledButton>
    );
});

Button.defaultProps = {
    sticky: false,
    fullWidth: false
};
