import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Overview from './views/Overview';
import LoadingIndicator from './components/LoadingIndicator';
import LubesDashboard from './views/LubesDashboard';
import FuelDashboard from './views/FuelDashboard';
import BaseView from './views/BaseView';
import CustomerHealthDashboard from './views/CustomerHealthDashboard';
import LubesKPIs from './views/LubesKPIs';

function App() {
    const { isLoading, error } = useAuth0();

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<BaseView />}>
                    <Route index element={<Overview />} />
                    <Route path="lubes-kpis" element={<LubesKPIs />} />
                    <Route
                        path="lubes-dashboard"
                        element={<LubesDashboard />}
                    />
                    <Route path="fuel-dashboard" element={<FuelDashboard />} />
                    <Route
                        path="customer-health"
                        element={<CustomerHealthDashboard />}
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
