import styled from 'styled-components';
import {
    color,
    display,
    fontSize,
    fontWeight,
    margin,
    padding,
    textAlign,
    variant
} from 'styled-system';

import { theme } from './theme.js';

export const Text = styled.span(
    {
        fontWeight: 'normal'
    },

    /**
     * Backwards compatibility
     *
     * The following props and variants are used throughout the app. Until we've
     * replaced all instances to use the new `variant` prop, these should remain
     * here.
     */
    fontWeight,
    fontSize,

    ({ caps }) => (caps ? { textTransform: 'uppercase' } : null),

    variant({
        prop: 'weight',
        variants: {
            semi: {
                fontWeight: theme.fontWeights.semiBold
            },
            bold: {
                fontWeight: theme.fontWeights.bold
            }
        }
    }),

    variant({
        prop: 'size',
        variants: {
            48: {
                fontSize: '48px',
                lineHeight: '64px'
            },
            32: {
                fontSize: '32px',
                lineHeight: '40px'
            },
            24: {
                fontSize: '24px',
                lineHeight: '32px'
            },
            20: {
                fontSize: '20px',
                lineHeight: '26px'
            },
            14: {
                fontSize: '14px',
                lineHeight: '18px'
            },
            12: {
                fontSize: '12px',
                lineHeight: '18px'
            },
            10: {
                fontSize: '10px',
                lineHeight: '14px'
            }
        }
    }),
    /**
     * end of backwards compatibility
     */

    display,
    textAlign,
    color,
    margin,
    padding,

    variant({
        prop: 'variant',
        variants: {
            extraSmall: {
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px'
            },
            small: {
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px'
            },
            body: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px'
            },
            subtitle2: {
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.03em',
                textTransform: 'uppercase'
            },
            subtitle1: {
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '24px'
            },
            title: {
                fontSize: '22px',
                fontWeight: 700,
                lineHeight: '32px'
            },
            headline: {
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '40px'
            },
            display: {
                fontSize: '40px',
                fontWeight: 700,
                lineHeight: '48px'
            }
        }
    })
);

Text.defaultProps = {
    theme
};
