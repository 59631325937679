export const SvgDiamond = (props) => (
    <svg width={32} height={22} viewBox="0 0 32 22" fill="none" {...props}>
        <path
            opacity={0.87}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.293 20.279a2.4 2.4 0 013.413 0l.53.535c1.674 1.581 4.21 1.581 5.773 0l6.818-6.895c1.564-1.693 1.564-4.257 0-5.838l-6.818-6.895c-1.674-1.581-4.21-1.581-5.773 0l-.53.535a2.4 2.4 0 01-3.412 0l-.53-.535c-1.674-1.581-4.21-1.581-5.773 0L1.173 8.081c-1.564 1.693-1.564 4.257 0 5.838l6.818 6.895c1.674 1.581 4.21 1.581 5.773 0l.53-.535zm5.29-13.208a1.8 1.8 0 010-2.532l.985-.995c.289-.292.798-.292 1.15.04l6.777 6.855c.29.292.29.807-.04 1.164l-6.777 6.853c-.29.292-.799.292-1.151-.04L13.75 11.56c-.29-.292-.29-.807.04-1.164a1.68 1.68 0 00-.081-2.356 1.636 1.636 0 00-2.33.08c-1.525 1.653-1.525 4.217.04 5.798l.998 1.01a1.8 1.8 0 010 2.531l-.984.996c-.29.292-.799.292-1.151-.04L3.505 11.56c-.29-.292-.29-.807.04-1.164l6.777-6.853c.29-.292.8-.292 1.152.04l6.776 6.855c.29.292.29.807-.04 1.164a1.68 1.68 0 00.081 2.356c.666.629 1.71.592 2.33-.081 1.525-1.652 1.525-4.216-.039-5.797l-.998-1.01z"
            fill="currentColor"
        />
    </svg>
);
SvgDiamond.defaultProps = {
    display: 'block'
};
