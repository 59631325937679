import styled from 'styled-components';

import leftIllustration from '../images/illustrations/Kueste-LI-Anschluss@2x.png';
import rightIllustration from '../images/illustrations/Kueste-RE-Anschluss@2x.png';
import { Box } from '../cdl/Box';
import { get } from '../cdl/get';

const LeftBackground = styled.div`
    background-image: url('${leftIllustration}');
    background-size: 400px 300px;
    width: 400px;
    height: 300px;
    position: fixed;
    left: -4px;
`;

const RightBackground = styled.div`
    background-image: url('${rightIllustration}');
    background-size: 400px 300px;
    width: 400px;
    height: 300px;
    position: fixed;
    right: -4px;
`;

const SubtleBackground = styled.div`
    position: fixed;
    height: 62px;
    background-color: ${get('colors.background.subtle')};
    width: 100%;
    bottom: 0;
`;

const BackgroundBox = styled(Box).attrs({ display: ['none', 'block'] })`
    overflow: hidden;
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    height: 300px;
    z-index: -1;
`;

export const LoginBackgroundImage = () => {
    return (
        <BackgroundBox>
            <SubtleBackground />
            <LeftBackground />
            <RightBackground />
        </BackgroundBox>
    );
};
