import { SuperBalls } from '@uiball/loaders';

const LoadingIndicator = () => (
    <div
        style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-50px',
            marginLeft: '-50px'
        }}
    >
        <SuperBalls size={40} color="#0068FF" />
    </div>
);

export default LoadingIndicator;
